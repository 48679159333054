.font-face-roboto,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
button,
.Toastify__toast {
  font-family: "Roboto", sans-serif !important;
}

/* Headings */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700 !important;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

/* Anchor tags (links) */
a {
  font-size: 1.1rem;
}

:root {
  --Overview-background: #f2f2f2 !important;
  --primary-background-color: white !important;
  --primary-text-color: black !important;
  --button-background-color: #6fac6a !important;
  --logo-color: #0d4d00 !important;
  --checkbox-color: #0d4d00 !important;
  --input-field-color: #f2f2f2 !important;
  --button-text-color: #000 !important;
  --button-hover-color: #90c48b !important;
  --location-link-color: #90c48b !important;
  --calendar-hover-color: #90c48b;
  --stepper-color: #fff;
  --stepper-active-color: #d1e9c6;
}

.red {
  --Overview-background: #fdd0c6 !important;
  --primary-background-color: #ff9a84 !important;
  --primary-text-color: #333 !important;
  --button-background-color: #ff5733 !important;
  --logo-color: #aa0000 !important;
  --checkbox-color: #aa0000 !important;
  --input-field-color: #ffc8bc !important;
  --button-text-color: #222 !important;
  --button-hover-color: #ff7f5e !important;
  --location-link-color: #aa0000 !important;
  --calendar-hover-color: #ff7f5e;
  --stepper-color: #ffece7;
  --stepper-active-color: #ff5733;
}

.primary {
  --Overview-background: #b4e1ff !important;
  --primary-background-color: #045f9d !important;
  --primary-text-color: #fff !important;
  --button-background-color: #3498db !important;
  --logo-color: #002f4e !important;
  --checkbox-color: #002f4e !important;
  --input-field-color: #002f4e !important;
  --button-text-color: #fff !important;
  --button-hover-color: #58a9ed !important;
  --location-link-color: #179df6 !important;
  --calendar-hover-color: #58a9ed;
  --stepper-color: #d4ecfc;
  --stepper-active-color: #004776;
}

.dark {
  --Overview-background: #000 !important;
  --primary-background-color: #222 !important;
  --primary-text-color: #fff !important;
  --button-background-color: #444 !important;
  --logo-color: #fff !important;
  --checkbox-color: #000 !important;
  --input-field-color: #000 !important;
  --button-text-color: #fff !important;
  --button-hover-color: #000 !important;
  --location-link-color: #179df6 !important;
  --calendar-hover-color: #444;
  --stepper-color: #636363;
  --stepper-active-color: #222;
}

/* -------------------Category page----------------------------------------------------------- */
.Category-page-container {
  background-color: var(--primary-background-color);
}

.Category-page-container .location-text {
  color: var(--primary-text-color);
}

.select-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

.select-button:hover {
  background-color: var(--button-hover-color);
}

/* -------------------Service page------------------------------------------------------ */
.location-page-container .location-text {
  color: var(--primary-text-color);
}

.location-page-container {
  background-color: var(--primary-background-color);
}

/* ------------------------Main page------------------------------------------------- */

.Booking_overview_container {
  background-color: var(--Overview-background);
}

.Booking_overview_container .step {
  background-color: var(--stepper-color);
}

.Booking_overview_container .step.active {
  background-color: var(--stepper-active-color);
  color: var(--button-text-color);
}

.Booking_overview_container .step.completed {
  background-color: var(--stepper-active-color);
  color: var(--button-text-color);
}

.Booking_overview_container .handle_back_button {
  color: var(--button-text-color);
}

/* ..................Calendar work--------------------------------------------- */

.Calendar-page-container {
  background-color: var(--primary-background-color);
}

/* .Calendar-page-container .react-calendar__tile {
  background-color: var(--primary-background-color);
} */

.Calendar-page-container .react-calendar__navigation__arrow {
  background-color: var(--primary-background-color);
}

.Calendar-page-container .react-calendar__tile--active {
  background-color: var(--button-hover-color) !important;
}

.Calendar-page-container .react-calendar__tile:hover {
  background-color: var(--button-hover-color);
}

.Calendar-page-container .react-calendar__navigation__label {
  background-color: var(--primary-background-color);
  color: var(--button-text-color);
}

.Calendar-page-container .react-calendar__month-view abbr {
  color: var(--button-text-color);
}

.Calendar-right-panel-wrapper .timeSlot-text:hover {
  background-color: var(--calendar-hover-color);
  color: var(--button-text-color);
}

.Calendar-timing-wrapper .Calendar-sideBar-service-info {
  background-color: var(--primary-background-color);
}

.Calendar-sideBar-more-info {
  color: var(--button-text-color);
}

.location-text {
  color: var(--button-text-color);
}

.Calendar-right-panel-wrapper .timeSlot-text {
  background-color: var(--primary-background-color);
  color: var(--button-text-color);
}

.container-footer-info span {
  color: var(--button-text-color);
}

/* ------------------Details page------------------------------------------------------------------ */

.details-page-container {
  background-color: var(--primary-background-color);
}

.user_selected_info .location_color {
  color: var(--location-link-color) !important;
}

.checbox_input_field:checked {
  background-color: var(--checkbox-color);
}

.input-container input {
  background-color: var(--input-field-color);
  color: var(--button-text-color) !important;
}

.rc-anchor-light {
  background-color: var(--primary-background-color) !important;
}

/* --------------------------------Confirmation Accordian-------------------------------------------------------------- */
.header_logo_wrapper {
  width: 160px;
  overflow: hidden;
}

.header_logo_wrapper img {
  object-fit: cover;
  width: 160px;
}

.app_logo_text {
  color: var(--logo-color);
}

.MuiPaper-root-MuiAccordion-root {
  border: none !important;
  background-color: #fff !important;
}

.MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #fff !important;
  padding: 0px !important;
  margin: -6px !important;
}

.MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin-left: -5px !important;
}

/* .css-pwcg7p-MuiCollapse-root {
  border-left: 2px solid #aaaaaa !important;
} */

.MuiAccordionDetails-root {
  margin-bottom: -35px !important;
}

.booking_date_time_selection_wrapper .user-confirmation-form {
  height: auto !important;
}

/* .css-78njj9 {
  background-color: #fff !important;
}

.css-1bdpvgx {
  border: none !important;
}

.css-c4sutr {
  border-left: 2px solid #b7b7b7 !important;
}

.css-1fpbxjw {
  border-top: none !important;
}

.css-78njj9 {
  margin: -7px !important;
  padding: 0px !important;
} */

/* -----------------Media query------------------------------------------------------------------- */

@media (max-width: 600px) {
  .booking_date_time_selection_wrapper .user-confirmation-form {
    height: auto !important;
  }
}

@media (max-width: 400px) {
  .header_logo_wrapper,
  .header_logo_wrapper img {
    width: 120px;
  }
}

.d-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.d-flex .nhsuk-radios {
  margin: 0;
}

.accordion-sec {
  margin-top: 20px;
}

.btn-class {
  background-color: #222222 !important;
  color: #fff !important;
  min-width: 150px;
  margin: 3px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #ccc;
  height: 40px;
  width: 30px;
  font-size: 15px;
}

@media (min-width: 1024px) {
  /* Adjust the width as needed for large screens */
  .booking_details_footer {
    display: flex;
    justify-content: space-between;
    /* margin-left: 50px; */
    /* margin-right: 50px; */
  }

  .booking_details_footer button {
    width: 100% !important;
    height: 60px;
  }
}

@media (max-width: 768px) {
  /* Adjust the width breakpoint for small screens */
  .booking_details_footer button {
    width: 100% !important;
  }
}

.cancel-booking-btn {
  background-color: rgb(201, 34, 34) !important;
  font-size: 16px !important;
}

.cancel-booking-btn:hover {
  background-color: rgb(159, 9, 9) !important;
}

.cancel-booking-btn:disabled,
.cancel-booking-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: rgb(159, 9, 9) !important;
  text-decoration: line-through !important;
}

.cancelation_btn_wrapper {
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: -15px;
}

@media (max-width: 767px) {
  .cancelation_btn_wrapper {
    position: fixed;
    z-index: 1000;
    margin-top: -86px;
  }
}

.booking_details_footer .reschedule_btn {
  width: 100% !important;
}

.user_personal_details_wrapper {
  display: flex !important;
  margin-left: -10px !important;
}

.user_controls_label {
  color: #222 !important;
  /* margin-top: 20px; */
  margin-top: 0px !important;
  font-weight: bold;
}
.user_controls_seperate_label {
  color: #222 !important;
  margin-top: 20px;
  margin-top: 0px !important;
  font-weight: bold;
  /* font-size: 14px; */
  /* width: 58px; */
  /* height: 16px; */
}

.user_control_wrapper {
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 31%);
  /* background-color: #f2f2f2; */
  border-radius: 10px;
}

.user_control_wrapper .MuiFormGroup-root {
  margin-left: 10px !important;
}

.user_control_wrapper input {
  background: #fff !important;
}

.user_control_wrapper .switch-content-reports-outer {
  width: 100%;
  border-radius: 11px;
  /* background-color: #fff; */
  /* background-color: #f2f2f2; */
  padding: 10px 15px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.user_control_wrapper .personal_info_wrapper {
  width: 100%;
  border-radius: 11px;
  /* background-color: #fff; */
  background-color: #f2f2f2;
  padding: 10px 15px;
  /* display: grid; */
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.user_details_main_wrapper {
  padding: 20px;
  /* background-color: blue; */
  padding: 32px;
  /* gap: 0px; */
  border-radius: 20px;
  border: 1px 0px 0px 0px;
}

.user_details_main_wrapper .login_button {
  text-align: center !important;
}

.reshcedule_tab_wrapper .MuiTabs-flexContainer {
  /* justify-content: space-around; */
}

.reshcedule_tab_wrapper .MuiTab-root.Mui-selected {
  color: #222 !important;
}

.reshcedule_tab_wrapper .tabs_text {
  font-size: 16px !important;
  text-transform: none !important;
}

.MuiTabs-root {
  background-color: #f2f2f2;
  box-shadow: 0px 4px 6px 0px rgb(0 0 0 / 31%);
}

.booking_calendar_controll_wrapper {
  padding: 0px !important;
  padding-bottom: 20px !important;
}

.user_details_main_wrapper .MuiOutlinedInput-root {
  background-color: #fff !important;
  border-radius: 10px !important;
}

.modal-footer-btns {
  display: flex;
  justify-content: space-between;
}

.modal-body-content span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
}

.modal-body-content .cancel-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.modal-footer-btns button {
  width: 100%;
  margin: 10px;
  background-color: #222 !important;
}

.modal-footer-btns button:hover {
  background-color: #000 !important;
}

.loading-component-wrapper {
  align-items: center;
  text-align: center;
}

.cancel-loading-wrapper {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  height: 100vh;
  /* Full viewport height (optional) */
}

.user_details_main_wrapper .booking_details_footer {
  display: flex;
  justify-content: flex-end;
}

.inline-not-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 14px;
}

/* For small screens (mobile devices) */
@media (max-width: 767px) {
  .user_details_main_wrapper {
    padding: 2px !important;
  }

  .form-group {
    text-align: start !important;
  }

  .user_control_wrapper .switch-content-reports-outer {
    padding: 10px 8px !important;
  }

  .user_control_wrapper {
    padding: 5px !important;
  }
}
.booking-sub-header {
  display: flex;
  justify-content: space-between;
}

/*  */
.pharmacy-logo-wrapper {
  justify-content: center;
  display: flex;
}
.pharmacy-logo {
  width: 120px;
  height: 120px;
  border: 1px solid #cccccc;
}

.user_control_wrapper .radio_buttons_outer {
  width: 100%;
  border-radius: 11px;
  /* background-color: #fff; */
  /* background-color: #f2f2f2; */
  padding: 0px 15px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}
.radio_buttons_label {
  display: flex;
}

.radio_button_wrapper {
  padding: 0px 40px;
}

.user_controls_label {
  color: #222 !important;
  margin-top: 20px;
}
.user_controls_textField {
  display: grid;
  grid-template-columns: 75% 20%;
  /* align-items: center; */
  justify-content: space-around;
}
.user_controls_textField.doses_text_Field {
  display: inline-flex;
}
.user_controls_select {
  width: 100%;
  border-radius: 11px;
  padding: 0px 15px;
  display: flex;
  /* grid-template-columns: auto auto; */
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.type-dropdown {
  display: inline-flex;
  width: 33%;
}

.section_save_button {
  /* width: 103px; */
  width: Fixed (103px) px;
  height: Fixed (34px) px;
  top: 3px;
  padding: 10px 40px 10px 40px;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: #5d3ae8;
  color: #ffffff !important;
  padding: 10px 40px 10px 40px;
  margin-bottom: 28px;
}

@media (min-width: 40.0625em) {
  .section_save_button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}
.section_save_button:link,
.section_save_button:visited,
.section_save_button:active,
.section_save_button:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #4622d5;
}

.template_save_button {
  width: 514px;
  height: 44px;
  top: 3px;
  padding: 10px 40px 10px 40px;
  gap: 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: #3ebf32;
  color: #ffffff !important;
  padding: 10px 40px 10px 40px;
  margin-bottom: 28px;
}

@media (min-width: 40.0625em) {
  .template_save_button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}
.template_save_button:link,
.template_save_button:visited,
.template_save_button:active,
.template_save_button:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #3fbb34;
}
.booking-sub-header-logo-wrapper {
  display: flex;
}
.booking-sub-header-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.reshcedule-booking-header {
  font-weight: 600;
  font-size: 65px;
}

.personal_info_value {
  color: #888888;
  font-size: large;
  margin: 2px;
}

/* .collapse-content .MuiCollapse-wra {
  columns: 2 !important;
} */

.isCountrySection .MuiCollapse-wrapperInner {
  columns: 2 !important;
}

.isCountrySection .type-dropdown {
  width: 100% !important;
  display: unset;
}

.isCountrySection .MuiCheckbox-root {
  padding: 5px 5px 5px 0px !important;
}

/* .isCountrySection .user_control_wrapper .MuiFormGroup-root
{
  margin-left: unset !important;
} */

.template_control-inl {
  display: inline;
}
