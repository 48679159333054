.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.nav-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #08649e;
  padding: 10px;
}

.pre-consultation-form {
  background-color: #f2f2f2;
  width: 100%;
  /* height: 1000px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.pre-consultation-form .confirmation_main_wrapper{
  margin-bottom: 20px !important;
}

.login_form {
  padding: 30px;
  background-color: #fff;
  width: 80%;
  height: auto;
  left: 50%;
  top: 5%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login_form h3 {
  font-size: 16px;
  font-weight: bold;
}

.login_form h1 {
  color: red;
}

.form-group {
  margin-bottom: 15px;
  text-align: justify;
}

label {
  display: flex;
  margin-bottom: 5px;
  color: #565656;
}

input,
select {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #444;
}

/* Styling for radio buttons */
input[type="radio"] {
  margin-right: 5px;
}

/* Additional styling for the form container */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login_button {
  background-color: #222;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-top: 50px;
}

.login_button:hover {
  background-color: #000;
}

.sign-in-link {
  margin-top: 30px;
}

.pre-consultation-form .error {
  margin-left: 10px;
  color: #e94141;
  display: flex;
}

.pre-consultation-form .MuiFormControl-root {
  display: flex !important;
}

.pre-consultation-form .MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
  border: 1px solid #444 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.pre-consultation-form .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #7e7e7e !important;
}

.pre-consultation-form .MuiStack-root>.MuiTextField-root {
  width: 100% !important;
}

.service_title_wrapper {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_title_wrapper p {
  font-size: 20px;
  margin-top: 10px;
  color: #6e6e6e;
}

.booking_back_button {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: 0.2s linear;
  width: fit-content;
  margin-bottom: 15px;
  color: #999;
  text-decoration: none !important;
}
.booking_back_button p{
  display: contents !important;
}

.booking_back_button:hover {
  transition: 0.2s linear;
  color: #444;
}

.banking_details {
  margin-bottom: 1rem;
}

/* .banking_details .input-group {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #f9f9a1 !important;
  padding: 8px !important;
} */

.banking_details .service_title_wrapper {
  display: flex !important;
  align-items: center !important;
  flex-grow: 1 !important;
  background-color: #f9f9a1 !important;
}

.banking_details .card-logo {
  margin-right: 8px !important;
  width: 40px !important;
  height: 24px !important;
  padding-left: 10px !important;
}

.banking_details .input-field {
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  color: #000 !important;
  flex-grow: 1 !important;
}

.input-field:focus {
  outline: none !important;
}

.banking_details .exp-date {
  width: 135px !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  text-align: right !important;
  margin-right: 16px !important;
}

.banking_details .exp-date:focus, .cvc:focus {
  outline: none !important;
}

.banking_details .cvc {
  width: 75px !important;
  border: none !important;
  background-color: transparent !important;
  font-size: 16px !important;
  text-align: right !important;
}

.banking_details .exp-date::placeholder, .cvc::placeholder {
  color: #777 !important;
}

.banking_details .input-group input:focus {
  outline: none !important;
}
.banking_details input{
  margin-bottom: 0px !important;
}
.verified-message{
  color: green;
  margin-top: 10px;
}

.loading-dots {
  font-size: 14px;
  margin-top: 5px;
}

.loading-dots span {
  animation: blink 1s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}
.react-tel-input .form-control{
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid #000 !important;
}
.react-tel-input .flag-dropdown{
  border: none !important;
}
.react-tel-input .selected-flag{
  border: 1px solid #000 !important;
  border-right: 1px solid #acacac !important;
}
.react-tel-input .form-control:focus {
  border: 1px solid #000 !important;
  box-shadow: none;
}


@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

